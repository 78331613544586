import { Helmet } from 'react-helmet-async';
import { useMatches } from 'react-router-dom';

export function HeadTitle() {
  const matches = useMatches();
  const pageId = matches[matches.length - 1].id;
  const titles: { [key: string]: string } = {
    home: 'Mariscos estilo Sinaloa',
    events: 'Eventos',
    contact: 'Contacto',
    marques: 'Menú Lomas del Marqués',
  };
  const title = titles[pageId];

  return (
    <Helmet>
      {!title}
      <title>El Lonche del Capitán {title ? `- ${title}` : ''}</title>
    </Helmet>
  );
}
